import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A two-week workshop around the New Gondwana Map project (IGCP-628) was held in
Rio de  Janiero from June 22 until July 3. There was a full programme of talks
and software instruction  classes and the many aspects of producing the new map
were discussed by specialists from many  parts of the southern continents. The
following week, July 6-10, Colin Reeves gave his 'African  Geodynamics' course
over five days at the State University of Rio de Janiero for a group of around
20 postgraduate students. `}</p>
    <p>{`The systematic accumulation of up-to-date geological information from the
southern continents is  proceding apace at the GIS laboratory at the university
dedicated to this project. The importation  of the provisional geological
outlines from all constituent continents into CPSL's Atlas software was
achieved and ongoing research topics related to fundamental questions about
Gondwana  reassembly were discussed. Collaboration will continue in the months
ahead. The geological line  work around Sri Lanka in the CVR re-assembly (in the
process of publication) is shown in the  figure below by way of example. Africa
(Mozambique, green) appears to the west, India (yellow) to  the north and
Antarctica (blue) to the southeast. The grid is in units of one degree. `}</p>
    <p><em parentName="p">{`2015 September 7`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      